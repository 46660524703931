.block {
  margin-top: 80px;

  padding: 32px;
  border-radius: 16px;
  border: 1px solid var(--color-brand);

  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  @media screen and (max-width: 930px) {
    flex-direction: column;
    gap: 32px;
    align-items: unset;
  }

  .content {
    max-width: 720px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .control {
      display: flex;
      gap: 16px;
    }
  }
  .image {
    flex: 1;
    img {
      display: block;
      margin: auto;
      width: 210px;
      height: 170px;
    }
  }
}