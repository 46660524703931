.instruction {
  margin-top: 80px;

  padding: 32px;
  border-radius: 16px;
  background-color: var(--color-background);

  display: flex;
  flex-direction: row;
  gap: 40px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
  img {
    display: block;
    width: 100%;
    max-width: 281px;
    max-height: 209px;
  }
}
.steps {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .step {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 8px 32px;
    .title {
      font-size: 24px;
      @media screen and (max-width: 1000px) {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .number {
      font-size: 64px;
      font-weight: bold;
      color: var(--color-brand);
      @media screen and (max-width: 600px) {
        font-size: 40px;
      }
    }
  }
}