@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --color-brand: #5BCC81;
  --color-add-1: #CCE2D7;
  --color-add-2: #D8A625;
  --color-white: #FFFFFF;
  --color-text: #2B2F45;
  --color-background: #F5F8F7;
  --color-border: #E2E5E9;
}

// App
body, html {
  background: #fff;
  color: var(--color-text);
  font-family: 'Montserrat', sans-serif;;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  margin: 0;
  height: 100%;
}
title {
  display: block;
  font-weight: 600;
  font-size: 24px;
  color: var(--color-brand);
  margin: 32px 0;
}

h2 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: bold;
}

h3 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}

// content
.content {
  max-width: 1160px;
  margin: auto;
  padding: 0 15px 0px 15px;
}

.bold {
  font-weight: bold;
}

.caption {
  font-size: 12px;
  font-weight: normal;
}

.green {
  color: var(--color-green);
}