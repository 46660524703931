.instruction {
  margin-top: 80px;
}

.blocks {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  @media screen and (max-width: 930px) {
    gap: 20px;
  }
  flex-wrap: wrap;
}

.block {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 32px;
  border-radius: 16px;
  background-color: var(--color-white);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);


  img {
    display: block;
    margin: auto;
    width: 230px;
    height: 195px;
  }

}