.banner {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 40px;
  padding: 40px;
  height: 400px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: unset;
    padding: 20px;
    gap: 20px;
    height: unset;
  }
  
  //@media screen and (max-width: 1024px) {
  //  height: 400px;
  //}
  //@media screen and (max-width: 600px) {
  //  height: 500px;
  //}
  //@media screen and (max-width: 500px) {
  //  height: 600px;
  //}

  
  border-radius: 24px;
  background-color: var(--color-background);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  .title {
    flex: 1;
    font-size: 48px;
    @media screen and (max-width: 1000px) {
      font-size: 40px;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
    //width: 500px;
    //@media screen and (max-width: 768px) {
    //  width: 400px;
    //}
    //@media screen and (max-width: 600px) {
    //  width: 100%;
    //}
  }

  .form {
    width: 280px;
    @media screen and (max-width: 768px) {
      width: unset;
    }
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    background-color: var(--color-white);
    border-radius: 8px;

    .label {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    .sum {
      color: var(--color-add-2);
      font-weight: bold;
    }

  }


  
}