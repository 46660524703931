.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .row {
    padding: 24px 0;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);

    display: flex;
    gap: 16px;
    justify-content: space-between;

    .checkbox {
      margin-top: 8px;
      font-size: 12px;
    }

    .sum {
      white-space: nowrap;
      font-weight: bold;
    }

  }
  .control {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }
}
