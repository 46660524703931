.header {
  width: calc(100% - 30px);
  max-width: 1160px;
  padding: 0 15px 0 15px;
  margin: 40px auto;
  @media screen and (max-width: 1000px) {
    margin: 32px auto;
  }
  @media screen and (max-width: 600px) {
    margin: 24px auto;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .control {
      display: flex;
      gap: 8px;
    }

  }

  .logo {
    width: 228px;
    height: 64px;
    @media screen and (max-width: 1000px) {
      width: 171px;
      height: 48px;
    }
    @media screen and (max-width: 600px) {
      width: 114px;
      height: 32px;
    }
  }
}